function get() {
    return new Promise((resolve, reject) => {
        axios
            .get('v1/coupon/index')
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

function create(params) {
    return new Promise((resolve, reject) => {
        axios
            .post('v1/coupon/create', params, {
                'content-type': 'multipart/form-data'
            })
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

function update(params) {
    return new Promise((resolve, reject) => {
        axios
            .post('v1/coupon/update', params, {
                'content-type': 'multipart/form-data'
            })
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

function remove(id) {
    return new Promise((resolve, reject) => {
        axios
            .post(`v1/coupon/delete?coupon_id=${id}`)
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

const Coupon = {
    get,
    create,
    update,
    remove,
}

let axios = null

export default $axios => {
    axios = $axios
    return Coupon
}
