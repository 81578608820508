<template>
  <v-app id="flexy" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`">
    <snackbar />
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    document.querySelector('.spinner-section').remove();
  }
};
</script>

<style>
.v-input input {
  max-height: initial !important;
}

.only-print {
  display: none;
}

@media print {

  .v-data-footer {
    display: none !important;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  .only-print {
    display: block !important;
  }
}

.container.fill-height {
  align-items: start !important;
}
</style>