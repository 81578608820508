function login(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/login", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function me(params) {
  return new Promise((resolve, reject) => {
    axios
      .get("v1/me", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getRoles() {
  return new Promise((resolve, reject) => {
    axios
      .get("v1/role")
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function addRole(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/role", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function updateRole(id, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(`v1/role/${id}`, params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getAllUsers(page = 1, queryParam = "") {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/users?page=${page}&queryParam=${queryParam}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getCustomer({ page = 1, role_id = "", queryParam = "" } = "") {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `v1/customers?page=${page}&role_id=${role_id}&queryParam=${queryParam}`
      )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getCustomerDetails({ page = 1, role_id = "", customer_id } = "") {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `v1/customer?page=${page}&role_id=${role_id}&customer_id=${customer_id}`
      )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getDoctorDetails({ page = 1, role_id = "", customer_id } = "") {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `v1/doctor/edit?page=${page}&role_id=${role_id}&customer_id=${customer_id}`
      )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function createCustomer(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/customer/create", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function updateCustomer(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/customer/update", params, {
        "content-type": "multipart/form-data",
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function updateDoctor(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/doctor/update", params, {
        "content-type": "multipart/form-data",
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function autoCompleteCustomer(search = "") {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/user/autocomplete?queryParam=${search}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function autoCompleteAdmins(search = "") {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/customers/autocomplete?queryParam=${search}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function addNewCustomer(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/user/register", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function profileUpdate(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/profile/update", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getTransactions(params) {
  const {
    page = 1,
    itemsPerPage,
    status,
    types,
    fromDate = "",
    toDate = "",
    mobile_number = "",
    trans_type = "",
  } = params;
  return new Promise((resolve, reject) => {
    axios
      .get(
        `v1/transactions?page=${page}&itemsPerPage=${itemsPerPage}&gateway_ids=${types}&is_paid=${status}&fromDate=${fromDate}&toDate=${toDate}&mobile_number=${mobile_number}&trans_type=${trans_type}`
      )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function removeRole(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v1/role/${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getDoctorsWithoutPermission({ page = 1, role_id = "" } = "") {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/doctor/show?page=${page}&role_id=${role_id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function updateWebsiteUser(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/user/edit", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getUserInfoByMobileNumber(mobile) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/user/details?mobile_number=${mobile}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

const Auth = {
  login,
  me,
  getRoles,
  updateRole,
  addRole,
  getCustomer,
  getCustomerDetails,
  createCustomer,
  updateCustomer,
  addNewCustomer,
  autoCompleteCustomer,
  profileUpdate,
  getAllUsers,
  getTransactions,
  removeRole,
  getDoctorsWithoutPermission,
  updateWebsiteUser,
  getDoctorDetails,
  updateDoctor,
  autoCompleteAdmins,
  getUserInfoByMobileNumber,
};

let axios = null;

export default ($axios) => {
  axios = $axios;
  return Auth;
};
