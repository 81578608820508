function get() {
  return new Promise((resolve, reject) => {
    axios
      .get("v1/marketing/index")
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function create(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/marketing/create", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function update(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/marketing/update", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function send(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/marketing/send?notification_id=${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

const Notifications = {
  get,
  create,
  update,
  send,
};

let axios = null;
export default ($axios) => {
  axios = $axios;
  return Notifications;
};
