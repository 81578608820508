import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";
import goTo from "vuetify/es5/services/goto";
import { MODULES_NAMES } from "./constants";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "booking-calendar",
          path: "/",
          component: () => import("@/views/dashboards/booking-calendar/index"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: "Services",
          path: "/services",
          component: () => import("@/views/dashboards/services/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.SERVICES],
          },
        },
        {
          name: "edit-services",
          path: "/services/:id",
          component: () => import("@/views/dashboards/services/_id/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.SERVICES],
          },
        },
        {
          name: "Discounts",
          path: "/discounts",
          component: () => import("@/views/dashboards/discounts/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.COUPONS],
          },
        },
        {
          name: "discount-reports",
          path: "/discounts/:id",
          component: () => import("@/views/dashboards/discounts/_id/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.COUPONS],
          },
        },
        {
          name: "Branches",
          path: "/branches",
          component: () => import("@/views/dashboards/branches/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.BRANCHES],
          },
        },
        {
          name: "Departments",
          path: "/departments",
          component: () => import("@/views/dashboards/departments/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.DEPARTMENTS],
          },
        },
        {
          name: "Users",
          path: "/users",
          component: () => import("@/views/dashboards/users/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.USERS],
          },
        },
        {
          name: "Doctors",
          path: "/doctors",
          component: () => import("@/views/dashboards/doctors/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.DOCTORS],
          },
        },
        {
          name: "edit-doctor",
          path: "/doctors/:id",
          component: () => import("@/views/dashboards/doctors/_id/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.DOCTORS],
          },
        },
        {
          name: "Roles",
          path: "/roles",
          component: () => import("@/views/dashboards/roles/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.ROLES],
          },
        },
        {
          name: "Admins",
          path: "/admins",
          component: () => import("@/views/dashboards/admins/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.ADMIN],
          },
        },
        {
          name: "Reports",
          path: "/reports",
          component: () => import("@/views/dashboards/reports/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.BOOKINGS_REPORT],
          },
        },
        {
          name: "NewReports",
          path: "/new-reports",
          component: () => import("@/views/dashboards/new-reports/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.NEW_REPORT],
          },
        },
        {
          name: "Transactions",
          path: "/transactions",
          component: () => import("@/views/dashboards/transactions/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.TRANSACTIONS_REPORT],
          },
        },
        {
          name: "Earning",
          path: "/earning-report",
          component: () => import("@/views/dashboards/earning/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.EARNING_REPORT],
          },
        },
        {
          name: "AdminsReport",
          path: "/admin-report",
          component: () => import("@/views/dashboards/admin-report/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.EARNING_REPORT],
          },
        },
        {
          name: "Jobs",
          path: "/jobs",
          component: () => import("@/views/dashboards/jobs/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.JOBS],
          },
        },
        {
          name: "edit-jobs",
          path: "/jobs/:id",
          component: () => import("@/views/dashboards/jobs/_id/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.JOBS],
          },
        },
        {
          name: "Tickets",
          path: "/tickets",
          component: () => import("@/views/dashboards/tickets/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.TICKETS],
          },
        },
        {
          name: "Notifications",
          path: "/notifications",
          component: () => import("@/views/dashboards/notifications/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.NOTIFICATIONS],
          },
        },
        {
          name: "Request",
          path: "/request",
          component: () => import("@/views/dashboards/request/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.NOTIFICATIONS],
          },
        },
        {
          name: "FranchiseRequests",
          path: "/franchise-requests",
          component: () => import("@/views/dashboards/franchise/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.FRANCHISE],
          },
        },
        {
          name: "ContactUsRequests",
          path: "/contact-us-requests",
          component: () => import("@/views/dashboards/contact-us/index"),
          meta: {
            requiresAuth: true,
            anyCan: ["is-admin", MODULES_NAMES.FRANCHISE],
          },
        },
      ],
    },

    {
      path: "/",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Login",
          path: "/login",
          component: () => import("@/views/authentication/FullLogin"),
          meta: {
            guest: true,
          },
        },
        {
          name: "Error",
          path: "error",
          component: () => import("@/views/authentication/Error"),
        },
        {
          name: "CRM",
          path: "/crm",
          component: () => import("@/views/dashboards/crm/index"),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "*",
      redirect: { name: "booking-calendar" },
    },
  ],
});

import NProgress from "nprogress";

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.loggedIn) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.guest)) {
    if (store.state.loggedIn) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  }

  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    // NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
