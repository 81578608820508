function get() {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/menu`)
      .then(response => {

        console.log(response);

        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}



const Service = {
  get
}

let axios = null

export default $axios => {
  axios = $axios
  return Service
}
