function get(page = 0) {
  const url = !page ? 'v1/department' : `v1/department?page=${page}`
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function add(params) {
  return new Promise((resolve, reject) => {
    axios
      .post('v1/department/create', params, {
        'content-type': 'multipart/form-data'
      })
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function update(params) {
  return new Promise((resolve, reject) => {
    axios
      .post('v1/department/update', params)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function remove(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v1/department/delete?department_id=${id}`)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

const Service = {
  get,
  add,
  update,
  remove
}

let axios = null

export default $axios => {
  axios = $axios
  return Service
}
