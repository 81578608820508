export const LABELS = [
  {
    id: 0,
    name: "Hide",
  },
  {
    id: 1,
    name: "Show",
  },
  {
    id: 2,
    name: "Hide in Website",
  },
];

export const getLabelName = (id) => {
  return LABELS.find((val) => val.id === id)?.name;
};

export const DAY_NAMES = [
  {
    id: 1,
    name: "Monday",
  },
  {
    id: 2,
    name: "Tuesday",
  },
  {
    id: 3,
    name: "Wednesday",
  },
  {
    id: 4,
    name: "Thursday",
  },
  {
    id: 5,
    name: "Friday",
  },
  {
    id: 6,
    name: "Saturday",
  },
  {
    id: 7,
    name: "Sunday",
  },
];

export const REGIONS = [
  {
    id: 1,
    en_name: "Riyadh",
    ar_name: "الرياض",
  },
  {
    id: 2,
    en_name: "Medina",
    ar_name: "المدينة المنورة",
  },
  {
    id: 3,
    en_name: "Dammam",
    ar_name: "الدمام",
  },
];

export const MODULES_NAMES = {
  ADMIN: "admin",
  BRANCHES: "branches",
  COUPONS: "coupons",
  DOCTORS: "doctors",
  JOBS: "jobs",
  REPORTS: "reports",
  NEW_REPORT: "new_report",
  EARNING_REPORT: "earning_reports",
  BOOKINGS_REPORT: "bookings_reports",
  TRANSACTIONS_REPORT: "transactions_reports",
  TRANSACTIONS: "transactions",
  ROLES: "roles",
  SERVICES: "services",
  DEPARTMENTS: "departments",
  USERS: "users",
  TICKETS: "tickets",
  NOTIFICATIONS: "notifications",
  REQUEST: "request",
  FRANCHISE: "franchise",
};

export default {
  MODULES_NAMES,
};
