function get(params = {}) {
  const { page = 0, itemsPerPage = 25, parent_id = null } = params
  const url = !page ? 'v1/service' : `v1/service?page=${page}&itemsPerPage=${itemsPerPage}&parent_id=${parent_id}`
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function add(params) {
  return new Promise((resolve, reject) => {
    axios
      .post('v1/service/create', params, {
        'content-type': 'multipart/form-data'
      })
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function update(params) {
  return new Promise((resolve, reject) => {
    axios
      .post('v1/service/update', params, {
        'content-type': 'multipart/form-data'
      })
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function remove(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v1/service/delete?service_id=${id}`)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function getDoctorServices(doctorId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/service_by_branch?doctor_id=${doctorId}`)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function sortService(params) {
  return new Promise((resolve, reject) => {
    axios
      .post('v1/service/update_sort', params)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })

  })
}

const Service = {
  get,
  add,
  update,
  remove,
  getDoctorServices,
  sortService
}

let axios = null

export default $axios => {
  axios = $axios
  return Service
}
