function get() {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/jobs`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function update(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/jobs/update", params, {
        "content-type": "multipart/form-data",
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function create(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/jobs", params, {
        "content-type": "multipart/form-data",
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function remove(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v1/jobs/${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getCandidateList(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/jobs/candidates?job_id=${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getFranchiseRequestsList() {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/franchise`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getContactUsRequestList() {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/contact_us`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

const Gallery = {
  get,
  update,
  create,
  remove,
  getCandidateList,
  getFranchiseRequestsList,
  getContactUsRequestList,
};

let axios = null;

export default ($axios) => {
  axios = $axios;
  return Gallery;
};
