function get(page = 0) {
  const url = !page ? 'v1/branch' : `v1/branch?page=${page}`
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function add(params) {
  return new Promise((resolve, reject) => {
    axios
      .post('v1/branch/create', params, {
        'content-type': 'multipart/form-data'
      })
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function update(params) {
  return new Promise((resolve, reject) => {
    axios
      .post('v1/branch/update', params, {
        'content-type': 'multipart/form-data'
      })
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function remove(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`v1/branch/delete?branch_id=${id}`)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function getDoctorBranches(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/doctor?customer_id=${id}`)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function changeBranch(id) {
  const params = {
    branch_id: id
  }
  return new Promise((resolve, reject) => {
    axios
      .post('v1/setDefaultBranch', params)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

function sortBranch(params) {
  return new Promise((resolve, reject) => {
    axios
      .post('v1/branch/update_sort', params)
      .then(response => {
        return resolve(response.data)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

const Service = {
  get,
  add,
  update,
  remove,
  getDoctorBranches,
  changeBranch,
  sortBranch
}

let axios = null

export default $axios => {
  axios = $axios
  return Service
}
