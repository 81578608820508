import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import VueFeather from "vue-feather";
import Cookies from "js-cookie";
import axios from "axios";
import Snakbar from "./components/Snackbar.vue";
import dayjs from "dayjs";
import VueI18n from "vue-i18n";
import VueCompositionAPI from "@vue/composition-api";
import acl from "./acl";
import CONSTANTS from "./constants";

Vue.use(VueCompositionAPI);

Vue.use(VueI18n);

Vue.component("snackbar", Snakbar);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const data =
      typeof error.response.data.message === "string"
        ? [error.response.data.message]
        : error.response.data.message;
    const snackbar = {
      show: true,
      data,
      color: "red darken-1",
    };
    store.commit("SET_SNACKBAR", snackbar);
    console.log("axios: ", error);
  }
);

// PROJECT: API
import Auth from "./api/auth";
import Service from "./api/service";
import Department from "./api/department";
import Branch from "./api/branch";
import Doctor from "./api/doctor";
import Menu from "./api/menu";
import Coupon from "./api/coupon";
import Initial from "./api/initial";
import Reports from "./api/reports";
import Gallery from "./api/gallery";
import Jobs from "./api/jobs";
import Tickets from "./api/tickets";
import Notifications from "./api/notifications";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

let $axios = axios;
$axios.auth = Auth(axios);
$axios.service = Service(axios);
$axios.doctor = Doctor(axios);
$axios.department = Department(axios);
$axios.branch = Branch(axios);
$axios.menu = Menu(axios);
$axios.coupon = Coupon(axios);
$axios.initial = Initial(axios);
$axios.reports = Reports(axios);
$axios.gallery = Gallery(axios);
$axios.jobs = Jobs(axios);
$axios.tickets = Tickets(axios);
$axios.notification = Notifications(axios);

Vue.prototype.$constants = CONSTANTS;
Vue.prototype.$axios = $axios;
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$acl = acl;
window.$dayjs = dayjs;
window.$axios = $axios;
window.$constants = CONSTANTS;
window.$acl = acl;

Vue.use(VueFeather);
Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(acl);

import en from "./locales/en.json";
import ar from "./locales/ar.json";
const messages = {
  en,
  ar,
};
const lng = Cookies.get("_locale") || "ar";
const i18n = new VueI18n({
  locale: lng, // set locale
  messages, // set locale messages
});

function InitApp() {
  return new Vue({
    vuetify,
    store,
    router,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}

const token = Cookies.get("token");
$axios.defaults.headers.lang = store.state.locale || "ar";
if (token) {
  $axios.defaults.headers.common["Authorization"] = `Bearer ${token} `;
  $axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  getUser();
} else {
  window.$app = InitApp();
}

function getUser() {
  try {
    Promise.all([
      getBranches(),
      getServices(),
      getDepartments(),
      me(),
      getRoles(),
    ]).then(() => {
      window.$app = InitApp();
    });
  } catch (_) {
    console.log("catch");
    window.$app = InitApp();
  }
}

async function me() {
  try {
    const { data } = await $axios.auth.me();
    store.commit("SET_LOGGED_IN", true);
    store.commit("SET_USER", data.user);
  } catch (e) {
    console.log(e);
  }
}

async function getBranches() {
  try {
    const { data } = await $axios.initial.getBranches();
    store.commit("SET_BRANCHES", data.branches);
  } catch (e) {
    console.log(e);
  }
}

async function getServices() {
  try {
    const { data } = await $axios.initial.getServices();
    store.commit("SET_SERVICES", data.services);
  } catch (e) {
    console.log(e);
  }
}

async function getDepartments() {
  try {
    const { data } = await $axios.initial.getDepartments();
    store.commit("SET_DEPARTMENTS", data.departments);
  } catch (e) {
    console.log(e);
  }
}

async function getRoles() {
  try {
    const { data } = await $axios.initial.getRoles();
    store.commit("SET_ROLES", data.roles);
    // store.commit('SET_PERMISSIONS', data.permissions);
  } catch (e) {
    console.log(e);
  }
}

// async function getDoctors() {
//   try {
//     const { data } = await $axios.auth.getCustomer({ role_id: 2 });
//     store.commit('SET_DOCTORS', data);
//   } catch (e) {
//     console.log(e)
//   }
// }
