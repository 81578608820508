function shift(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/schedule/shift_list?customer_id=${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function createShift(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/schedule/create", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function deleteShift({ id, is_all = false, doctor_id = "" }) {
  const params = {
    shift_id: id,
    is_all,
    doctor_id,
  };
  return new Promise((resolve, reject) => {
    axios
      .post("v1/schedule/delete", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function exceptionalShift(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/schedule/exceptional_shifts_list?customer_id=${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function deleteExceptionalShift({ id, is_all = false, doctor_id = "" }) {
  const params = {
    excep_shift_id: id,
    is_all,
    doctor_id,
  };
  return new Promise((resolve, reject) => {
    axios
      .post("v1/schedule/delete_excep_shift", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function createExceptionalShift(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/schedule/create_excep_shift", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function addServices(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/doctor/add_branch", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getBookings(params) {
  return new Promise((resolve, reject) => {
    axios
      .post(`v1/bookings`, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getBranchDoctors() {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/branch/show`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function createBooking(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/create/bookings", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function updateBooking(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/update/bookings", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function cancelBooking(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/cancel/bookings?order_id=${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getBookingsDetails(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/bookings/details?order_id=${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getBookingsDetailsHistory(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/bookings/history?order_id=${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function checkIn(id, status) {
  return new Promise((resolve, reject) => {
    axios
      .post(`v1/checkin?order_id=${id}&checkin=${status ? 1 : 0}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function removeDoctor(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/customers/delete?customer_id=${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function sortDoctor(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/doctor/update_sort", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function allBadges() {
  return new Promise((resolve, reject) => {
    axios
      .get("v1/doctor/specializations")
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

const Service = {
  shift,
  createShift,
  deleteShift,
  exceptionalShift,
  createExceptionalShift,
  deleteExceptionalShift,
  addServices,
  getBookings,
  getBranchDoctors,
  createBooking,
  getBookingsDetails,
  getBookingsDetailsHistory,
  updateBooking,
  cancelBooking,
  checkIn,
  removeDoctor,
  sortDoctor,
  allBadges,
};

let axios = null;

export default ($axios) => {
  axios = $axios;
  return Service;
};
