import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api'; // Ensure this is installed
Vue.use(VueCompositionAPI); // VueCompositionAPI must be used as plugin before any function, otherwise see your console if warning/errpr
import { computed } from '@vue/composition-api'; // import computed function
import { createAcl, defineAclRules } from 'vue-simple-acl';
import store from '../store/store'
import { MODULES_NAMES } from '../constants'
import router from '../router'

const user = computed(() => store.state.user);

const rules = () => defineAclRules((setRule) => {
    // Define a simple rule for ability with no argument
    setRule('is-admin', (user) => !!user.value.is_admin);

    Object.values(MODULES_NAMES).map((name) =>
        setRule(name, (user) => user.value.permissions && Object.prototype.hasOwnProperty.call(user.value?.permissions, name))
    )

    setRule('page-rule', (user, module, type) => {
        if (user.value.is_admin) return true
        return !!user.value?.permissions[module]?.find(perm => perm.name === `${type}_${module}` || false)
    })
});

const simpleAcl = createAcl({
    user,
    rules,
    router,
    onDeniedRoute: '/'
});

export default simpleAcl;