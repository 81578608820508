function get(params, page) {
  return new Promise((resolve, reject) => {
    axios
      .post(`v1/reports?page=${page}`, params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getReports(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/reports/records", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function getNewReports(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v2/reports/records", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

const Reports = {
  get,
  getReports,
  getNewReports,
};

let axios = null;

export default ($axios) => {
  axios = $axios;
  return Reports;
};
