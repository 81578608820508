function getBranches() {
    return new Promise((resolve, reject) => {
        axios
            .get(`v1/dropdown/branches`)
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

function getServices() {
    return new Promise((resolve, reject) => {
        axios
            .get(`v1/dropdown/services`)
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

function getDepartments() {
    return new Promise((resolve, reject) => {
        axios
            .get(`v1/dropdown/departments`)
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

function getRoles() {
    return new Promise((resolve, reject) => {
        axios
            .get(`v1/dropdown/roles`)
            .then(response => {
                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}


const Service = {
    getBranches,
    getServices,
    getDepartments,
    getRoles,
}

let axios = null

export default $axios => {
    axios = $axios
    return Service
}
