import Vue from "vue";
import Vuetify from "vuetify/lib";
import Cookies from 'js-cookie'
import "@/scss/vuetify/overrides.scss";
import store from "../store/store"
Vue.use(Vuetify);

const lng = Cookies.get('_locale') || 'ar'
if (lng) {
  store.commit('SET_LANGUAGE', lng)
}

const theme = {
  primary: "#c21c22", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  info: "#03c9d7",
  success: "#00c292",
  accent: "#fc4b6c",
  default: "#fb9778",
  warning: "#fec90f",
  error: "#fc4b6c",
  secondary: "#03c9d7",
};

import en from 'vuetify/lib/locale/en';
import ar from 'vuetify/lib/locale/ar';

const messages = {
  en: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Items per page:',
        pageText: '{0}-{1} of {2}',
      },
    },
  },
  ar: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'مواد لكل صفحة:',
        pageText: '{0}-{1} من {2}',
      },
    },
  },
}

export default new Vuetify({
  lang: {
    locales: { en, ar },
    current: 'lng',
    messages
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    dark: false, // If you want to set dark theme then dark:true else set to false
  },
  rtl: store.state.locale === 'ar', // If you want to set rtl theme then rtl:true else set to false
});
