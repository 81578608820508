function get() {
    return new Promise((resolve, reject) => {
        axios
            .get(`v1/gallery`)
            .then(response => {

                console.log(response);

                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

function remove(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`v1/gallery/${id}`)
            .then(response => {

                console.log(response);

                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

function upload(params) {
    return new Promise((resolve, reject) => {
        axios
            .post('v1/gallery', params, {
                'content-type': 'multipart/form-data'
            })
            .then(response => {

                console.log(response);

                return resolve(response.data)
            })
            .catch(error => {
                return reject(error)
            })
    })
}

const Gallery = {
    get,
    remove,
    upload
}

let axios = null

export default $axios => {
    axios = $axios
    return Gallery
}
