<template>
    <v-snackbar v-model="snackbar" :color="$store.state.snackbar.color" right :timeout="3000">
        <p v-for="(err, index) in $store.state.snackbar.data" :key="`index-${index}`">
            {{ err }}
        </p>
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    computed: {
        snackbar: {
            get() {
                return this.$store.state.snackbar.show
            },
            set(value) {
                const snackbar = {
                    show: value,
                    data: [],
                    color: ''
                }
                this.$store.commit('SET_SNACKBAR', snackbar)
            }
        }
    }
}
</script>