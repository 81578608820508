
export default {
    state: {
    },
    getters: {

    },
    mutation: {
    },
    actions: {

    }
}