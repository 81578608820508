import Vue from "vue";
import Vuex from "vuex";
import auth from './auth'
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "#fafafa",
    setHorizontalLayout: false, // Horizontal layout
    loggedIn: false,
    services: [],
    branches: [],
    departments: [],
    roles: [],
    permissions: [],
    sidebarItems: [],
    doctors: [],
    user: null,
    isPasswordModel: false,
    isSettingsModel: false,
    locale: 'ar',
    snackbar: {
      show: false,
      data: [],
    }
  },
  mutations: {
    SET_SIDEBAR_ITEMS(state, payload) {
      state.sidebarItems = payload;
    },
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    SET_LOGGED_IN(state, bool) {
      state.loggedIn = bool;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_BRANCHES(state, payload) {
      state.branches = payload;
    },
    SET_SERVICES(state, payload) {
      state.services = payload;
    },
    SET_DEPARTMENTS(state, payload) {
      state.departments = payload;
    },
    SET_ROLES(state, payload) {
      state.roles = payload;
    },
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload;
    },
    SET_DOCTORS(state, payload) {
      state.doctors = payload
    },
    SET_SNACKBAR(state, snackbar) {
      state.snackbar = snackbar
    },
    TOGGLE_PASSWORD_MODEL(state, bool) {
      state.isPasswordModel = bool
    },
    TOGGLE_SETTINGS_MODEL(state, bool) {
      state.isSettingsModel = bool
    },
    SET_LANGUAGE(state, lng) {
      state.locale = lng
    }
  },
  actions: {
    async getBranches({ commit }) {
      console.log(this.$axios)
      try {
        const { data } = await this.$axios.branch.get();
        commit('SET_BRANCHES', data.branches);
      } catch (e) {
        console.log(e)
      }
    },
    async getServices({ commit }) {
      try {
        const { data } = await this.$axios.service.get();
        commit('SET_SERVICES', data.services);
      } catch (e) {
        console.log(e)
      }
    },
    async getDepartments({ commit }) {
      try {
        const { data } = await this.$axios.department.get();
        commit('SET_DEPARTMENTS', data.departments);
      } catch (e) {
        console.log(e)
      }
    },
    async getUser({ commit }) {
      try {
        const { data } = await this.$axios.auth.me();
        commit('SET_LOGGED_IN', true);
        commit('SET_USER', data.user);
      } catch (e) {
        console.log(e)
      }
    },
    getInitialData({ dispatch }) {
      try {
        return Promise.all([dispatch('getBranches'), dispatch('getServices'), dispatch('getDepartments')]).then(() => {
          console.log('all resolved')
        })
      } catch (e) {
        console.log(e)
      }
    }
  },
  getters: {},
  modules: {
    auth
  }
});
