function get(params) {
  return new Promise((resolve, reject) => {
    axios
      .get("v1/tickets", {
        params: { ...params },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function add(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/tickets", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function update(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/tickets/update", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function sendMessage(params) {
  return new Promise((resolve, reject) => {
    axios
      .post("v1/tickets/response", params)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function details(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/tickets?id=${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function history(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/tickets/history/${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

function sendReminder(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`v1/tickets/send/reminder?ticket_id=${id}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

const Tickets = {
  get,
  add,
  update,
  details,
  sendMessage,
  history,
  sendReminder,
};

let axios = null;
export default ($axios) => {
  axios = $axios;
  return Tickets;
};
